exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-resume-jsx": () => import("./../../../src/pages/resume.jsx" /* webpackChunkName: "component---src-pages-resume-jsx" */),
  "component---src-pages-tinywins-index-jsx": () => import("./../../../src/pages/tinywins/index.jsx" /* webpackChunkName: "component---src-pages-tinywins-index-jsx" */),
  "component---src-pages-uses-jsx": () => import("./../../../src/pages/uses.jsx" /* webpackChunkName: "component---src-pages-uses-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-category-jsx": () => import("./../../../src/templates/category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */),
  "component---src-templates-tiny-win-jsx": () => import("./../../../src/templates/tiny-win.jsx" /* webpackChunkName: "component---src-templates-tiny-win-jsx" */)
}

